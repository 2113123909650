import React, { useState, useRef, useEffect } from "react";
import "./App.css";
import openIcon from "./static/media/open-icon.svg";
import closeIcon from "./static/media/close-icon.svg";
import botIcon from "./static/media/bot-icon.png";
import maximizeIcon from "./static/media/maximize-icon.svg";
import minimizeIcon from "./static/media/minimize-icon.svg";
import closeIconDark from "./static/media/close-icon-dark.svg";
import "./colors.css";
import {
  ChatMessage,
  sendFeedbackToAPI,
  sendMessageToAPI,
  Source,
} from "./API";
import SourceBlock from "./SourceBlock";
import ReactMarkdown from "react-markdown";

const ENABLE_SOURCES = true; //process.env.REACT_APP_ENABLE_SOURCES !== "false";

interface BaseMessage {}

interface UserMessage extends BaseMessage {
  type: "user";
  text: string;
}

export interface AIMessage extends BaseMessage {
  type: "ai";
  id: string;
  text: string;
  runId?: string;
  feedback?: "positive" | "negative" | null;
  isGenerating?: boolean;
}

interface SourceMessage extends BaseMessage {
  type: "source";
  sources: Source[];
}

type Message = UserMessage | AIMessage | SourceMessage;

function FeedbackButtons({
  messageId,
  feedback,
  onFeedback,
}: {
  messageId: string;
  feedback?: "positive" | "negative" | null;
  onFeedback: (id: string, type: "positive" | "negative") => void;
}) {
  return (
    <div className="feedback-buttons">
      <button
        className={`feedback-button ${feedback === "positive" ? "active" : ""}`}
        onClick={() => onFeedback(messageId, "positive")}
        aria-label="Thumbs up"
      >
        👍
      </button>
      <button
        className={`feedback-button ${feedback === "negative" ? "active" : ""}`}
        onClick={() => onFeedback(messageId, "negative")}
        aria-label="Thumbs down"
      >
        👎
      </button>
    </div>
  );
}

function App(): JSX.Element {
  const [isChatOpen, setIsChatOpen] = useState<boolean>(false);
  const [isFullscreen, setIsFullscreen] = useState<boolean>(false);

  const [isPressed, setIsPressed] = useState<boolean>(false);
  const [inputValue, setInputValue] = useState<string>("");
  const [messages, setMessages] = useState<Message[]>([]);
  const [isGenerating, setIsGenerating] = useState<boolean>(false);
  const [chatHistory, setChatHistory] = useState<ChatMessage[]>([]);

  const textareaRef = useRef<HTMLTextAreaElement>(null);
  const chatHistoryRef = useRef<HTMLDivElement>(null);

  const setChatState = (isOpen: boolean): void => {
    setIsChatOpen(isOpen);
    if (!isOpen) {
      setIsFullscreen(false);
    }
  };

  const toggleMinimize = (): void => {
    setIsFullscreen(!isFullscreen);
    if (textareaRef.current) {
      textareaRef.current.focus();
    }
  };

  const handleMouseDown = (): void => {
    setIsPressed(true);
  };

  const handleMouseUp = (): void => {
    setIsPressed(false);
    setChatState(!isChatOpen);
  };

  const handleMouseLeave = (): void => {
    setIsPressed(false);
  };

  const handleInputChange = (
    e: React.ChangeEvent<HTMLTextAreaElement>,
  ): void => {
    setInputValue(e.target.value);
    adjustTextareaHeight();
  };

  const handleFeedback = (
    messageId: string,
    feedbackType: "positive" | "negative",
  ) => {
    const scrollPosition = chatHistoryRef.current?.scrollTop;

    setMessages((prevMessages) =>
      prevMessages.map((msg) => {
        if (msg.type === "ai" && msg.id === messageId) {
          const updatedMessage = {
            ...msg,
            feedback: msg.feedback === feedbackType ? null : feedbackType,
          };

          // Send feedback to API
          sendFeedbackToAPI(updatedMessage, (errorMessage) => {
            // Handle error (you might want to show this to the user)
            console.error(errorMessage);
          });

          return updatedMessage;
        }
        return msg;
      }),
    );

    requestAnimationFrame(() => {
      if (chatHistoryRef.current && scrollPosition !== undefined) {
        chatHistoryRef.current.scrollTop = scrollPosition;
      }
    });
  };

  const sendMessage = (): void => {
    if (inputValue.trim()) {
      const newMessage: Message = {
        type: "user",
        text: inputValue.trim(),
      };
      setMessages([...messages, newMessage]);
      setInputValue(""); // clear out the textfield
      adjustTextareaHeight(true); // and reset the height

      const newAIMessage: AIMessage = {
        id: Date.now().toString(),
        type: "ai",
        text: "...",
        isGenerating: true,
      };

      // Add the 'thinking' AI dummy message
      setMessages((prevMessages) => [...prevMessages, newAIMessage]);
      setIsGenerating(true);

      console.log("Chat history", chatHistory);
      // Send the message to the api and stream the response
      sendMessageToAPI(
        inputValue.trim(),
        chatHistory,
        (responseMessage, runId) =>
          handleNewMessage(responseMessage, runId, newAIMessage.id),
        (responseMessage, runId) =>
          handleNewMessageAdditive(responseMessage, runId, newAIMessage.id),
        (sources) => handleCompletion(sources, newAIMessage.id),
        (errorMessage) => handleError(errorMessage, newAIMessage.id),
      );
    }
  };

  const handleKeyPress = (
    e: React.KeyboardEvent<HTMLTextAreaElement>,
  ): void => {
    // enables Enter to send a message instead of inserting a new line
    if (e.key === "Enter" && !e.shiftKey && !isGenerating) {
      e.preventDefault();
      sendMessage();
    }
  };

  const adjustTextareaHeight = (reset: boolean = false): void => {
    const textarea = textareaRef.current;
    if (textarea) {
      textarea.style.height = "auto"; // automatically sets the height to wrap to one row
      if (!reset) {
        // if we don't want to reset the height (wrap to one row), we set it to the scroll height which makes it expanding
        textarea.style.height = textarea.scrollHeight + "px";
      }
    }
  };

  const handleNewMessageAdditive = (
    responseMessage: string,
    runId: string,
    messageId: string,
  ) => {
    setMessages((prevMessages) =>
      prevMessages.map((msg) =>
        msg.type === "ai" && msg.id === messageId
          ? {
              ...msg,
              text:
                msg.text === "..." && responseMessage !== ""
                  ? responseMessage
                  : msg.text + responseMessage,
              runId: runId,
            }
          : msg,
      ),
    );
  };

  const handleNewMessage = (
    responseMessage: string,
    runId: string,
    messageId: string,
  ) => {
    console.log(responseMessage);
    setMessages((prevMessages) =>
      prevMessages.map((msg) =>
        msg.type === "ai" && msg.id === messageId
          ? { ...msg, text: responseMessage, runId: runId }
          : msg,
      ),
    );
  };

  const handleCompletion = (sources: Source[], messageId: string): void => {
    // unblock UI
    setIsGenerating(false);

    // get the latest state of the messages using a state capture
    setMessages((currentMessages) => {
      // Add to the chat history
      const updatedMessages = currentMessages.map((msg) =>
        msg.type === "ai" && msg.id === messageId
          ? { ...msg, isGenerating: false }
          : msg,
      );
      const lastAIMessage =
        currentMessages.find(
          (msg): msg is AIMessage => msg.type === "ai" && msg.id === messageId,
        )?.text || "";
      const lastUserMessage = inputValue.trim();
      const newChatMessage: ChatMessage = {
        user_message: lastUserMessage,
        ai_response: lastAIMessage,
      };
      setChatHistory([...chatHistory, newChatMessage]);

      // Add the sources to the UI
      const sourceMessage: Message = {
        type: "source",
        sources: sources,
      };
      return [...updatedMessages, sourceMessage];
    });
  };

  const handleError = (errorMessage: string, messageId: string): void => {
    setMessages((prevMessages) => {
      // Local copy of the last message
      const updatedMessages = prevMessages.map((msg) => {
        if (msg.type === "ai" && msg.id === messageId) {
          return { ...msg, text: errorMessage };
        }
        return msg;
      });

      // If no matching message was found, add a new error message
      if (
        !updatedMessages.some(
          (msg) => msg.type === "ai" && msg.id === messageId,
        )
      ) {
        updatedMessages.push({
          id: messageId,
          type: "ai",
          text: errorMessage,
        });
      }

      return updatedMessages;
    });
    setIsGenerating(false);
  };

  useEffect(() => {
    // scrolls to the bottom when a new message is added
    if (chatHistoryRef.current) {
      chatHistoryRef.current.scrollTop = chatHistoryRef.current.scrollHeight;
    }
  }, [messages]);

  useEffect(() => {
    // focuses on the textarea when the chat is opened
    if (isChatOpen && textareaRef.current) {
      textareaRef.current.focus();
    }
  }, [isChatOpen]);

  return (
    <div className={`App ${isFullscreen ? "fullscreen" : ""}`}>
      {/* <img src={bg} className="bg" alt="background"/> */}

      {!isFullscreen && (
        <button
          className={`chat-button ${isPressed ? "pressed" : ""}`}
          onMouseDown={handleMouseDown}
          onMouseUp={handleMouseUp}
          onMouseLeave={handleMouseLeave}
        >
          <img
            src={isChatOpen ? closeIcon : openIcon}
            alt="icon"
            className="chat-icon"
          />
        </button>
      )}
      <div
        className={`${isFullscreen ? "chat-window-fullscreen" : "chat-window"} ${isChatOpen ? "open" : ""}`}
      >
        <div className="chat-header-container">
          <div className="chat-header">
            <img src={botIcon} alt="Assistant" className="header-image" />
            <span className="header-text">Helpdeskový asistent</span>
          </div>
          <div className="header-buttons">
            <button className="header-button" onClick={toggleMinimize}>
              <img
                src={isFullscreen ? minimizeIcon : maximizeIcon}
                alt={`${isFullscreen ? "Minimize" : "Maximize"}`}
                className="button-icon"
              />
            </button>
            <button
              className="header-button"
              onClick={() => setChatState(false)}
            >
              <img src={closeIconDark} alt="Close" className="button-icon" />
            </button>
          </div>
        </div>
        <div className="chat-warning">
          <span className="warning-text">
            Informace mohou být nesprávné. vždy si je ověřujte.
          </span>
        </div>
        <div className="chat-history" ref={chatHistoryRef}>
          {messages.map((message, idx) => {
            switch (message.type) {
              case "source":
                return ENABLE_SOURCES ? (
                  <SourceBlock sources={message.sources} key={idx} />
                ) : (
                  ""
                );
              case "user":
                return (
                  <div key={idx} className="user-message-container">
                    <div className="message user-message">
                      <span>{message.text}</span>
                    </div>
                  </div>
                );
              case "ai":
                return (
                  <div key={message.id} className="ai-message-container">
                    <img src={botIcon} alt="AI" className="ai-icon" />
                    <div className="ai-message-content">
                      <div className="message ai-message">
                        <ReactMarkdown>{message.text}</ReactMarkdown>
                        {!message.isGenerating && (
                          <FeedbackButtons
                            messageId={message.id}
                            feedback={message.feedback}
                            onFeedback={handleFeedback}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                );
            }
          })}
        </div>
        <div className="chat-footer">
          <textarea
            ref={textareaRef}
            className="chat-input"
            value={inputValue}
            onChange={handleInputChange}
            onKeyPress={handleKeyPress}
            placeholder="Zeptejte se na cokoliv..."
            rows={1}
          />
          <button
            className="send-button"
            onClick={sendMessage}
            disabled={!inputValue.trim() || isGenerating}
          >
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                className="send-icon"
                d="M4.99839 11.25L2.29102 2.04492L21.908 11.9998L2.29102 21.9547L4.99829 12.75H10.0007V11.25H4.99839Z"
              />
            </svg>
          </button>
        </div>
      </div>
    </div>
  );
}

export default App;
