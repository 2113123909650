import React from 'react';
import './SourceBlock.css'; // Create a separate CSS file for styling
import linkIcon from './static/media/link-icon.svg';
import warningIcon from './static/media/warning-icon.svg';

const SourceBlock = ({ sources }) => {
    if (sources.length === 0) {
        return null;
    }

    return (
        <div className="source-block">
            <div className="source-header">
                <img src={linkIcon} alt="icon" className="source-icon" />
                <span>Zdroje</span>
            </div>
            <ol className="source-list">
                {sources.map((source, index) => (
                    <li key={index}>
                        {source.file_location && (
                            <a href={source.file_location} target="_blank" rel="noopener noreferrer">
                                {source.file_name}
                            </a>
                        )}
                    </li>
                ))}
            </ol>
        </div>
    );
};

export default SourceBlock;
